<template>
  <main class="table-container" v-if="agents.length">
    <table class="my-table">
      <thead class="my-thead">
        <tr>
          <th class="my-th" style="width: 17.5%; min-width: 150px">
            <p class="-stb">Agent Title</p>
          </th>
          <th class="my-th" style="width: 20%; min-width: 170px">
            <p class="-stb">Assigned To</p>
          </th>
          <th class="my-th" style="width: 17.5%; min-width: 170px">
            <p class="-stb">Organization</p>
          </th>
          <th class="my-th" style="width: 25%; min-width: 350px">
            <p class="-stb">Squad</p>
          </th>
          <th
            class="my-th"
            style="width: 5%"
            v-show="selectedStages.length > 1"
          >
            <p class="-stb">Stage</p>
          </th>
          <th class="my-th" style="width: 5%"></th>
          <th class="my-th" style="width: 5%"></th>
          <th class="my-th" style="width: 5%"></th>
        </tr>
      </thead>
      <tbody v-for="(agent, index) in agents" :key="agent">
        <tr
          class="tr-content"
          :class="[
            index % 2 ? 'bg-primary' : 'bg-secondary',
            { 'toggled-row': agent.toggled && getUsers[index] },
          ]"
        >
          <td @click="agent.toggled = !agent.toggled" class="my-td item-hover">
            <div class="flex-row">
              <i
                class="i-arrow-open-down"
                :class="{ rotate: agent.toggled }"
              ></i>
              <p class="-stb">
                {{ agent.agent.agent_name }}
              </p>
            </div>
          </td>
          <td @click="agent.toggled = !agent.toggled" class="my-td item-hover">
            <div class="flex-row" v-if="getUsers[index]">
              <DynaBadge
                :picturePath="getUsers[index].picture"
                :first_name="getUsers[index].first_name"
                :last_name="getUsers[index].last_name"
              />

              <div class="flex-column">
                <p class="-stb">
                  {{ getUsers[index].first_name }}
                  {{ getUsers[index].last_name }}
                </p>
                <p class="-str">{{ getUsers[index].tag }}</p>
              </div>
            </div>
            <div class="flex-row" v-else>
              <DynaBadge />
              <div class="flex-column">
                <p class="-stb">Not assigned</p>
              </div>
            </div>
          </td>

          <td class="my-td">
            <div class="flex-row" v-if="getOrgs[index].length">
              <DynaBadge
                :picturePath="getOrgs[index].logo"
                :first_name="getOrgs[index].name"
              />
              <div class="flex-column">
                <p class="-stb">{{ getOrgs[index].name }}</p>
              </div>
            </div>
            <div class="flex-row" v-else>
              <DynaBadge />
              <div class="flex-column">
                <p class="-stb">Nothing to show</p>
              </div>
            </div>
          </td>
          <td class="my-td">
            <div class="flex-row" v-if="agent.squads">
              <p
                class="-stb tag"
                v-for="squad in shownSquads(agent.squads)"
                :key="squad"
              >
                {{ squad }}
              </p>
              <p
                class="-stb additional-tag"
                v-if="hiddenSquads(agent.squads) > 0"
              >
                + {{ hiddenSquads(agent.squads) }}
              </p>
            </div>
            <div class="-stb empty-tag" v-else>No squad assigned</div>
          </td>
          <td
            class="my-td"
            style="max-width: 40px; padding-left: 30px"
            v-show="selectedStages.length > 1"
          >
            <p class="-stb">{{ getAgentStage(agent.frame_stage_id) }}</p>
          </td>
          <td class="my-td" style="max-width: 40px">
            <IconHierarchy
              title="Allow this person to act on my behalf"
              :onBehalf="agent.onMyBehalf"
              @click="agent.onMyBehalf = !agent.onMyBehalf"
            />
          </td>

          <td class="my-td" style="max-width: 40px">
            <IconEdit title="Edit Row" @click="setModal('update', agent)" />
          </td>
          <td class="my-td" style="max-width: 40px">
            <IconTrash @click="removeItem(agent.id)" title="Delete Row" />
          </td>
        </tr>
        <tr
          class="tr-toggled-content"
          v-show="getUsers[index] && agent.toggled"
        >
          <td class="my-td">
            <div class="flex-row" v-if="getUsers[index]">
              <DynaBadge
                :picturePath="getUsers[index].picture"
                :first_name="getUsers[index].first_name"
                :last_name="getUsers[index].last_name"
              />
              <div class="flex-column">
                <p class="-stb">
                  {{ getUsers[index].first_name }}
                  {{ getUsers[index].last_name }}
                </p>
              </div>
            </div>
          </td>
          <td class="my-td">
            <p class="-xstb toggled-text" v-if="getUsers[index]">
              {{ getUsers[index].email }}
            </p>
          </td>
          <td class="my-td" colspan="6">
            <p class="-xstb toggled-text" v-if="getUsers[index]">
              Contact: {{ getUsers[index].contact }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
  <main class="no-data" v-else>
    <NoData :item="'agent'" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import IconTrash from "../../../assets/icons/dynamicIcons/IconTrash.vue";
import IconEdit from "../../../assets/icons/dynamicIcons/IconEdit.vue";
import IconHierarchy from "../../../assets/icons/dynamicIcons/IconHierarchy.vue";
import DynaBadge from "../../utils/DynaBadge.vue";
import NoData from "../../utils/NoData.vue";
import { getUnitAgents } from "../../../services/requests/get/agents";
export default {
  components: { IconTrash, IconEdit, IconHierarchy, DynaBadge, NoData },
  data() {
    return {
      onBehalf: false,
      agents: [],
    };
  },
  methods: {
    shownSquads(squads) {
      if (squads.length <= 3) {
        return squads;
      } else {
        const res = squads.slice(0, 3);
        return res;
      }
    },
    hiddenSquads(squads) {
      const res = squads.slice(3, squads.length).length;
      return res;
    },
    removeItem(toRemove) {
      console.log("REMOVE ITEM: ", toRemove);
    },
    getAgentStage(frame_stage_id) {
      const stage = this.$store.state.stages.find(
        (stage) => stage.frame_stage_id == frame_stage_id
      );
      return stage.stage_value;
    },
    async getAgents(newSelectedStages) {
      if (newSelectedStages) {
        const res = await getUnitAgents(newSelectedStages, true);
        console.log(res);

        if (res.status != 200) return;

        this.agents = res.data;
      } else {
        const stagesArray = [this.$store.state.selectedStages];
        const res = await getUnitAgents(stagesArray, true);
        console.log(res);

        if (res.status != 200) return;

        this.agents = res.data;
      }
    },
    // will need mapGetters : user, selectedStages
    setModal(cmd, item) {
      this.$emit("setModal", cmd, item);
    },
  },
  computed: {
    ...mapGetters(["selectedStages"]),
    getUsers() {
      return this.agents.map((agent) => agent.user);
    },
    getOrgs() {
      return this.agents.map((agent) => agent.company);
    },
  },
  watch: {
    "$store.state.selectedStages": {
      handler: async function (newVal) {
        await this.getAgents(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.bg-primary {
  background: #f9fafb !important;
}
.bg-secondary {
  background: var(--white1) !important;
}
.table-container {
  width: 100%;
  height: auto;
}
.item-hover {
  cursor: pointer;
}
.my-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  transition: all 250ms ease;
}
.my-thead {
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
  background-size: 500% auto;
}

.my-th {
  padding: 8px;
  color: var(--white1);
}
.my-th:first-child {
  border-top-left-radius: 8px;
}
.my-th:last-child {
  border-top-right-radius: 8px;
}
.my-td {
  padding: 8px;
}

/* .tr-content {
  transition: 100ms ease;
} */
.toggled-row {
  position: relative;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.tr-content:hover {
  background: rgb(243, 248, 254) !important;
}
.tr-toggled-content {
  /* border-block: 1px solid var(--gray5); */
  background: var(--gray6);
}
.tr-toggled-content:last-child {
  box-shadow: inset 0px -8px 8px -6px rgba(0, 0, 0, 0.1);
}
.flex-row {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column p:last-child {
  color: var(--gray2);
}

.toggled {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  padding-inline: 12px;
}
.toggled-text {
  color: var(--gray2);
}
/*ICONS*/
i {
  transform: scale(0.5);
  cursor: pointer;
}

.rotate {
  transform: rotateZ(180deg) scale(0.5);
}

/*STATUS*/
.status-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: fit-content;
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 32px;
}
.completed {
  background: #e3ffef;
  color: #027a48;
}
.ongoing {
  background: #e0f6ff;
  color: #0044b9;
}
.awaiting-verification {
  white-space: nowrap;
  background: #f8edff;
  color: #c973ff;
}
thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.tag,
.additional-tag,
.empty-tag {
  display: inline;
  width: auto;
  padding-block: 4px;
  padding-inline: 12px;
  border-radius: 32px;
  background: #eff8ff;
  color: #175cd3;
}
.tag:first-child {
  color: #6941c6;
  background: #f9f5ff;
}
.tag:last-child {
  color: #3538cd;
  background: #eef4ff;
}
.additional-tag,
.empty-tag {
  color: #344054;
  background: #f2f4f7;
  white-space: nowrap;
}
</style>