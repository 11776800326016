import apiClient from "../../axios/axios";
import store from "../../../store/index";

export async function getUnitAgents(stagesArray, flattenState) {
  try {
    const endpoint = `/list_agents`;
    const data = {
      proj_unit_id: store.state.unitModule.unit.id,
      frame_stage_id: stagesArray,
    };

    const res = await apiClient.post(endpoint, data);
    // console.log("RES FROM STORE", res);

    return flattenState
      ? { status: res.status, data: res.data.flat() }
      : { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on agents store getter");
  }
}
